<template>
  <AppBackButton :analytics-name="`${analyticsName}-back`" @click="navigateToXeApp" />
</template>

<script>
import { AppBackButton } from '@oen.web.vue2/ui'


import { useAppStore, useEnvStore } from '@galileo/stores'

export default {
  name: 'XeCloseButtonLink',
  emits: ['click'],
  components: {
    AppBackButton,
  },
  props: {
    analyticsName: {
      type: String,
      default: '',
      required: () => {
        const envStore = useEnvStore()
        return !!envStore.env.VUE_APP_ANALYTICS_ENABLED
      },
    },
  },
  setup() {
    const appStore = useAppStore()
    return {
      navigateToXeApp: () => appStore.navigateToXeApp(),
    }
  },
}
</script>

<style scoped></style>
