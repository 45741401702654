<template>
  <div class="login-container">
    <AppSpinnerBig :loading="!initialized"></AppSpinnerBig>
    <LayoutPage v-if="initialized" analytics-name="login">
      <template #title>{{ title }}</template>
      <template #navRight>
        <XeCloseButtonLink analytics-name="login-back" />
      </template>
      <template #content>
        <form id="loginForm" @submit.prevent="submit">
          <p class="text-secondary-text pb-6">
            <!--
              When using router link and a tags navigate is required to maintain spa routing
              https://router.vuejs.org/api/#v-slot-api-3-1-0
            -->
            <RouterLink v-slot="{ href, navigate }" to="/registration" custom>
              <AppLink :href="href" analytics-name="login-register" @click="navigate">
                {{ $t('PageLogin.ButtonRegister').value }}
              </AppLink>
            </RouterLink>
          </p>
          <!--Form Errors-->
          <AppAlert v-if="loginError" class="mb-6">
            {{ loginError.message }}
            <AppAlertAction
              :href="loginError.href"
              :target="loginError.target"
              @click="loginError.onClick"
            >
              <template v-if="loginError.hrefText" #default>
                {{ loginError.hrefText }}
              </template>
            </AppAlertAction>
          </AppAlert>
          <!--Form Inputs-->

          <AppInputText
            v-model="validation.username.$value"
            type="email"
            analytics-name="login-email"
            :label="$t('PageLogin.LabelUsername').value"
            :validation="validation.username"
          />

          <AppInputPassword
            v-model="validation.password.$value"
            analytics-name="login-password"
            :label="$t('PageLogin.LabelPassword').value"
            :validation="validation.password"
            required
          />

          <!--Call to action-->
          <ButtonGroup class="mb-6">
            <AppButton
              theme="text"
              analytics-name="login-forgot-password"
              @click="$router.push('forgot-password')"
            >
              {{ $t('PageLogin.ButtonForgotPassword').value }}
            </AppButton>
          </ButtonGroup>
          <TermsConditions />
        </form>
      </template>
      <template #footer>
        <!--Submit-->
        <AppCardFooter>
          <AppButton
            form="loginForm"
            :disabled="validation.$anyInvalid"
            type="submit"
            :loading="login.loading"
            analytics-name="login-submit"
          >
            {{ $t('PageLogin.ButtonLogin').value }}
          </AppButton>
        </AppCardFooter>
      </template>
    </LayoutPage>
  </div>
</template>

<script>
import { computed, reactive, ref, toRef, onMounted, onBeforeMount } from '@vue/composition-api'
import { email, required } from '@vuelidate/validators'
import { usePromiseLazy, useValidation } from 'vue-composable'
import LayoutPage from '@galileo/components/LayoutPage/LayoutPage'
import TermsConditions from '@galileo/components/TermsConditions/TermsConditions'
import ButtonGroup from '@galileo/components/ButtonGroup/ButtonGroup'
import XeCloseButtonLink from '@galileo/components/XeCloseButtonLink/XeCloseButtonLink'
import { SEGMENT_EVENTS, SEGMENT_LOGIN_METHODS } from '@galileo/constants/segmentAnalytics'
import { useGalileoLoginUrlValidator } from '@galileo/composables/useGalileoLoginUrlValidator'

import { useRouter } from '@galileo/composables/useRouter'

import {
  AppSpinnerBig,
  AppButton,
  AppAlert,
  AppInputPassword,
  AppInputText,
  AppCardFooter,
  AppLink,
  AppAlertAction,
} from '@oen.web.vue2/ui'

import {
  useAuthStore,
  useAnalyticsStore,
  useAppStore,
  useI18nStore,
  useOtpStore,
  useEnvStore,
} from '@galileo/stores'

export default {
  name: 'Login',
  components: {
    ButtonGroup,
    TermsConditions,
    AppCardFooter,
    LayoutPage,
    AppSpinnerBig,
    AppInputText,
    AppInputPassword,
    AppAlert,
    AppButton,
    AppLink,
    AppAlertAction,
    XeCloseButtonLink,
  },
  setup() {
    const initialized = ref(false)
    const { $t } = useI18nStore()
    const analyticsStore = useAnalyticsStore()
    const router = useRouter()
    const title = $t('PageLogin.Title')
    const otpStore = useOtpStore()
    const appStore = useAppStore()
    const authStore = useAuthStore()
    const { isGalileoLoginUrl } = useGalileoLoginUrlValidator()

    onBeforeMount(async () => {
      if (!authStore.isAuthenticated) {
        if (isGalileoLoginUrl.value) {
          console.info('built-in login')
          if (!useEnvStore().useGalileoLogin) {
            await authStore.logout()
          }
          initialized.value = true
        } else {
          console.warn('redirection to accounts ui')
          await authStore.logout(true)
        }
      }
    })

    // Form Handling
    const usernameValidation = {
      $validator(v) {
        return email.$validator(v)
      },
      $message: computed(() => {
        return email.$message
      }),
    }

    const form = reactive({
      username: authStore.lastLogin,
      password: '',
    })
    const validation = useValidation({
      username: {
        $value: toRef(form, 'username'),
        usernameValidation,
        required,
      },
      password: { $value: toRef(form, 'password'), required },
    })
    const login = reactive(usePromiseLazy(() => authStore.login({ ...form, router })))

    const submit = async () => {
      try {
        authStore.loginMethod = SEGMENT_LOGIN_METHODS.EMAIL
        await login.exec()
      } catch (ex) {
        analyticsStore.track({
          event: SEGMENT_EVENTS.LOGIN_FAILED,
          traits: {
            errorType: 'Exception',
          },
        })
        appStore.logException('Exception during login', ex)
        appStore.messageBoxGenericError()
        return
      }

      if (login.error) {
        analyticsStore.track({
          event: SEGMENT_EVENTS.LOGIN_FAILED,
          traits: {
            errorType: login.error.code,
          },
        })

        switch (login.error.code) {
          /*
             Success = 1,
             UserNotFound = 10,
             InvalidPassword = 20,
             InvalidToken = 30,
             MigrationError = 40,
             OtpRequired = 50,
             OtpError = 60,
             NoContactData = 70,
             MobileNumberNotVerified = 80,
             BlockedAccount = 90,
             BlockedPassword = 100,
             ExceededSecurityQuestionsAttempts = 110,
             PrivateApiError = 120,
             InvalidFingerPrint = 130,
             ErrorSetFingerPrint = 140,
             UserNotLinked = 150,
             NoDeviceInformation = 160,
             MfaMigrationNotVerified = 170,
             BlockedByOtpFailedAttempts = 180
             CountryNotSupported = 220
             */
          case 100:
            // 100 BlockedPassword:
            // When the account is locked redirect to intermediate route to reset password
            login.error = null
            router.push({ name: 'AccountLocked' })
            break
          case 90:
          case 120:
          case 190:
            // 90 BlockedAccount:
            // 120 PrivateApiError:
            // 159 CustomerDisabled:
            // When the account is running into compliance issues, show support pages
            console.error('failed to login', login.error.code)
            break
          case 180:
            // 180 BlockedByOtpFailedAttempts:
            login.error = null
            router.push({ name: 'AccountLockedTooManyAttempts' })
            break
          default:
            appStore.logException('Unhandled login error code', login.error)
            break
        }
      } else if (login.result) {
        if (login.result.require2FA) {
          otpStore.setMaskedEmail(form.username)
          otpStore.setNextRoute(null)
          router.push('/otp/login')
        } else if (login.result.profiles && login.result.profiles.length > 1) {
          router.push({ name: 'ProfileSelection' })
        } else if (!login.result.hasProfile) {
          const msgBox = {
            title: $t('PageLogin.MissingProfileTitle').value,
            text: $t('PageLogin.MissingProfileText').value,
          }
          appStore.messageBoxGenericOk(msgBox.title, msgBox.text)
        }
      }
    }

    const loginError = computed(() => {
      if (login.error === null || login.error === undefined) {
        return null
      }

      const error = {
        message: $t('PageLogin.ErrorText').value,
        onClick: () => (login.error = null),
      }

      switch (login.error.code) {
        case 220:
          // CountryNotSupported = 220
          error.message = $t('PageLogin.CountryNotSupported.Text').value
          error.href = 'https://riamoneytransfer.app.link/SNBEDqLA0jb'
          error.hrefText = $t('PageLogin.CountryNotSupported.GetAppLink').value
          error.target = 'branchLink'
          break
      }

      return error
    })

    // Dispatch custom event to update service worker
    // Service worker will handle updates
    // TODO - Potentially move to store/composable for reusability
    onMounted(() => {
      //window.dispatchEvent(new Event(SERVICE_WORKER_EVENTS.UPDATE))
    })

    return {
      title,
      formValid: false,
      formError: false,
      validation,
      form,
      submit,
      login,
      initialized,
      $t,
      loginError,
    }
  },
}
</script>
